export const Blog8 = () => {
    return (
         <div>
            Occasional anxiety is an expected part of life. You might feel anxious when faced with a problem at work, taking an exam, or before making an important decision. But anxiety disorders involve more than temporary worry or fear. For a person with an anxiety disorder, the anxiety does not go away and can get worse over time. The symptoms that develop can interfere with daily activities such as job performance, school work, and relationships.

            <br/><br/> There are several types of anxiety disorders, including generalized anxiety disorder, panic disorder, and various phobia-related disorders. Symptoms of anxiety include restlessness, rapid heartbeat, upset stomach, difficulty concentrating, headache, irritability, sleeplessness, fatigue, sweating, and muscle tension.

            <br/><br/> The causes of anxiety disorders are not yet fully understood. Life experiences such as traumatic events, high stress levels, and medical conditions appear to trigger anxiety disorders in people who are already prone to anxiety. Inherited traits can also be a factor. Scientific studies indicate the imbalance of neurotransmitters such as norepinephrine, serotonin, dopamine, and gamma-aminobutyric acid, plays an important role in the development of anxiety.

            <br/><br/> Numerous studies have shown that acupuncture is an effective treatment for anxiety and stress disorders. When the body is under stress, an area of the brain called the hypothalamus releases neurochemicals, and acupuncture can calm this response to reduce the release of stress hormones. Acupuncture has also been shown to increase the release of endorphins (the body’s own “feel good” chemicals). Endorphins play an important role in the regulation of physical and emotional stress responses such as pain, heart rate, blood pressure, and digestive function.

            <br/><br/> Traditional Chinese Medicine is individualized medicine that treats each patient according to his or her unique needs. Treatments are tailored to each patient’s specific signs and symptoms, medical history, constitutional tendencies, and other relevant lifestyle factors. Thus, two patients who have been diagnosed with the same generalized anxiety disorder from a Western medicine perspective might receive different diagnoses from a Chinese medicine perspective and thus receive different treatments and advice. 

            <br/><br/> Acupuncture treats anxiety disorders by balancing the Yin and Yang of your body, soothing the Liver Qi, boosting Qi to flow smoothly in meridians, and calming your mind and spirit. 

            <br/><br/>References:
            <ul className="list-disc">
                <ol>
                    <li> <b>Expression and co-expression of serotonin and dopamine transporters in social anxiety disorder: a multitracer positron emission tomography study.</b> Hjorth, O. R., et al.Molecular Psychiatry, 2019, 1–10.
                    </li>
                    
                    <li> <b>Anxiety disorders and GABA neurotransmission: a disturbance of modulation.</b> Philippe Nuss. Neuropsychiatr Dis Treat. 2015; 11: 165–175.
                    </li>

                    <li>
                        <a href="https://www.nimh.nih.gov/health/topics/anxiety-disorders/index.html" target="_blank" className="text-2xl text-blue-500" rel="noreferrer">https://www.nimh.nih.gov/health/topics/anxiety-disorders/index.html
                        </a>
                    </li>
                </ol>
            </ul>
        </div>
    );
}
export default Blog8
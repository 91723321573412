export const Lamp = () => {
    return <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.55623 34H21.5593M14.4468 34L2 19.775L17.114 9.99539" stroke="#455C83"
              stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M19.7812 21.5532C15.9618 17.7338 15.9618 11.1476 19.7812 7.32822C23.4939 3.61551 30.1689 3.82177 34.0061 7.32822L19.7812 21.5532Z"
            stroke="#455C83" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M17.5442 10.127L15.4887 8.07147C14.8217 7.40465 14.4468 6.50015 14.4467 5.55695C14.4465 4.61376 14.821 3.70913 15.4878 3.04207C16.1547 2.37501 17.0592 2.00017 18.0024 2C18.9455 1.99983 19.8502 2.37436 20.5172 3.04118L22.5514 5.07534M24.2264 17.9969L24.5962 18.4841C25.4376 19.3172 26.5737 19.7845 27.7577 19.7845C28.9417 19.7845 30.0779 19.3172 30.9192 18.4841C32.5889 16.8251 32.6618 14.1811 31.1361 12.4385L30.4854 11.7824"
            stroke="#455C83" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}
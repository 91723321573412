export const Blog1 = () => {

    return (
        <div>
            <div>
                Qi (chee) is a unique Chinese word, which is hard to find a perfect English translation. The concept of Qi originally comes from ancient Chinese philosophy. Qi is the primary element, which composes the whole of the universe. In Traditional Chinese Medicine
                (TCM), the most popular concept of Qi is that of our life force or life energy, which is non-substantial, and circulates smoothly throughout the meridian system to maintain a balanced life. Qi is also the primary element composing the human
                body. Qi as life energy is widely used in TCM diagnosis and treatment.
                <br/><br/>
                Qi has different names according to its function. Such as the following.
                <div>
                    <ul className="list-disc">
                        <li>Primary Qi (Yuan Qi)- Relating to overall life force.
                        </li>
                        <li>Chest Qi (Zong Qi)- Relating to the chest region of the body.
                        </li>
                        <li>Nutritional Qi (Ying Qi)- Relating to overall nutrition.
                        </li>
                        <li>Defensive Qi (Wei Qi)- Relating to the bodies defense system.
                        </li>
                    </ul>
                </div>

                <br/>
                Different organs have there own Qi’s name, such as Kidney Qi, Lung Qi, Heart Qi, Stomach Qi, etc.

                <br/><br/>
                Think about your everyday activities such as your breathing, heartbeat, emotions, and even dreaming. All of these require Qi to perform their functions. If Qi is blocked, flowing insufficiently or deficient in some way, your health
                may be impacted. Problems such as pain, abdominal bloating or distention can all result from lack of properly flowing Qi energy.
            </div>
        </div>
    );
};

export default Blog1



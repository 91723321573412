import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import pic1 from './img/1.gif';
import pic2 from './img/2.gif';
import pic3 from './img/3.jpg';
import pic4 from './img/4.jpg';
import pic5 from './img/5.gif';


const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
    };

    const carouselItems = [
        {id: 1, imageUrl: `${pic1}`, caption: 'Slide 1'},
        {id: 2, imageUrl: `${pic2}`, caption: 'Slide 2'},
        {id: 3, imageUrl: `${pic3}`, caption: 'Slide 3'},
        {id: 4, imageUrl: `${pic4}`, caption: 'Slide 4'},
        {id: 5, imageUrl: `${pic5}`, caption: 'Slide 5'},
    ];

    return ( 
        <div className="place-content-center flex-1">
            <Slider {...settings}>
                {carouselItems.map((item) => (
                    <div key={item.id}>
                        <img src={item.imageUrl} className="w-full rounded-2xl" alt={item.caption}/>
                    </div>
                ))}
            </Slider>
        </div>    
    );
};

export default Carousel;
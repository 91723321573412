export const PriorityHealth = () => {
    return (
        <svg width="254" height="44" viewBox="0 0 254 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.2527 11.5001C35.818 1.52675 22.6239 -2.61091 12.7726 1.68969C7.53788 3.79394 2.60835 8.437 0.607791 14.405C0.568532 14.5206 0.617606 14.5372 0.680898 14.4309C7.05291 3.79631 21.9929 3.72782 28.3287 12.9312C33.636 20.4863 30.254 30.2565 21.5875 33.5369C28.9675 36.7157 37.5135 32.4765 40.429 25.2805C42.3674 21.0366 42.2908 15.6873 40.2527 11.5001Z" fill="#62C43A"/>
        <path d="M1.58094 31.9567C6.01575 41.9283 19.2097 46.0664 29.0611 41.766C34.2958 39.6622 39.2252 35.0185 41.2258 29.0526C41.2651 28.9367 41.2161 28.9181 41.1528 29.0248C34.7809 39.6599 19.8408 39.7294 13.5049 30.5255C8.19746 22.9685 11.5796 13.201 20.2461 9.91888C12.8662 6.74113 4.32024 10.9812 1.40469 18.1741C-0.533829 22.4188 -0.457366 27.77 1.58094 31.9567Z" fill="#009845"/>
        <path d="M61.1478 21.6666C63.4717 21.6666 64.8298 20.2792 64.8298 18.4675V18.4079C64.8298 16.3255 63.3808 15.2086 61.0573 15.2086H57.4662V21.6666H61.1478ZM52.8184 11.0142H61.4497C66.4897 11.0142 69.5378 14.0024 69.5378 18.3184V18.378C69.5378 23.2674 65.735 25.8015 60.997 25.8015H57.4662V32.1401H52.8184V11.0142Z" fill="#009845"/>
        <path d="M71.166 15.9616H75.7531V19.2217C76.6887 16.9878 78.1976 15.5402 80.914 15.6603V20.4586H80.6725C77.6244 20.4586 75.7531 22.3005 75.7531 26.1633V32.1398H71.166V15.9616Z" fill="#009845"/>
        <path d="M82.8451 15.9645H87.4324V32.1394H82.8451V15.9645ZM82.7246 10.1094H87.5532V14.1829H82.7246V10.1094Z" fill="#009845"/>
        <path d="M101.858 24.1428V24.0816C101.858 21.6968 100.138 19.6155 97.6031 19.6155C94.977 19.6155 93.4079 21.6357 93.4079 24.0205V24.0816C93.4079 26.4665 95.1281 28.5478 97.6627 28.5478C100.289 28.5478 101.858 26.5254 101.858 24.1428ZM88.8809 24.1428V24.0816C88.8809 19.4343 92.623 15.6611 97.6627 15.6611C102.673 15.6611 106.385 19.3731 106.385 24.0205V24.0816C106.385 28.7291 102.643 32.5022 97.6031 32.5022C92.5931 32.5022 88.8809 28.7902 88.8809 24.1428Z" fill="#009845"/>
        <path d="M107.834 15.9616H112.421V19.2217C113.356 16.9878 114.866 15.5402 117.582 15.6603V20.4586H117.341C114.292 20.4586 112.421 22.3005 112.421 26.1633V32.1398H107.834V15.9616Z" fill="#009845"/>
        <path d="M119.056 15.9645H123.644V32.1394H119.056V15.9645ZM118.936 10.1094H123.764V14.1829H118.936V10.1094Z" fill="#009845"/>
        <path d="M127.508 27.5521V19.8886H125.576V15.9641H127.508V11.8291H132.095V15.9641H135.897V19.8886H132.095V26.7993C132.095 27.8542 132.547 28.369 133.574 28.369C134.419 28.369 135.173 28.1562 135.836 27.7947V31.4766C134.871 32.0487 133.755 32.4125 132.216 32.4125C129.409 32.4125 127.508 31.2958 127.508 27.5521Z" fill="#009845"/>
        <path d="M148.935 15.9639H153.703L147.486 32.5332C146.248 35.8235 144.92 37.0591 142.174 37.0591C140.516 37.0591 139.277 36.6382 138.101 35.9435L139.64 32.6237C140.244 32.9857 140.997 33.2573 141.601 33.2573C142.386 33.2573 142.809 33.0152 143.201 32.2005L136.863 15.9639H141.721L145.405 26.9799L148.935 15.9639Z" fill="#009845"/>
        <path d="M164.504 11.0142H169.152V19.3733H177.722V11.0142H182.371V32.1401H177.722V23.6595H169.152V32.1401H164.504V11.0142Z" fill="#009845"/>
        <path d="M195.436 22.7839C195.163 20.7321 193.957 19.3437 192.025 19.3437C190.124 19.3437 188.887 20.7003 188.524 22.7839H195.436ZM184.027 24.1428V24.0816C184.027 19.4637 187.318 15.6611 192.025 15.6611C197.427 15.6611 199.902 19.8555 199.902 24.444C199.902 24.8064 199.872 25.2277 199.842 25.6512H188.584C189.037 27.7325 190.485 28.8196 192.538 28.8196C194.078 28.8196 195.194 28.3372 196.461 27.1595L199.087 29.4832C197.579 31.3539 195.406 32.5022 192.477 32.5022C187.619 32.5022 184.027 29.0914 184.027 24.1428Z" fill="#009845"/>
        <path d="M211.55 26.4369V25.6219C210.765 25.2596 209.74 25.0174 208.621 25.0174C206.66 25.0174 205.453 25.8007 205.453 27.2498V27.3108C205.453 28.5493 206.479 29.2738 207.958 29.2738C210.101 29.2738 211.55 28.0965 211.55 26.4369ZM201.018 27.492V27.4308C201.018 23.9011 203.703 22.2711 207.536 22.2711C209.166 22.2711 210.342 22.5427 211.489 22.9344V22.6627C211.489 20.7609 210.313 19.7059 208.018 19.7059C206.268 19.7059 205.031 20.0387 203.552 20.5798L202.406 17.0796C204.186 16.294 205.936 15.7822 208.683 15.7822C211.188 15.7822 212.998 16.4456 214.146 17.5935C215.353 18.8002 215.895 20.5798 215.895 22.7533V32.14H211.46V30.39C210.342 31.6262 208.804 32.4412 206.571 32.4412C203.522 32.4412 201.018 30.6911 201.018 27.492Z" fill="#009845"/>
        <path d="M218.551 10.1094H223.139V32.1394H218.551V10.1094Z" fill="#009845"/>
        <path d="M226.697 27.5521V19.8886H224.766V15.9641H226.697V11.8291H231.286V15.9641H235.087V19.8886H231.286V26.7993C231.286 27.8542 231.737 28.369 232.765 28.369C233.609 28.369 234.363 28.1562 235.028 27.7947V31.4766C234.06 32.0487 232.945 32.4125 231.405 32.4125C228.599 32.4125 226.697 31.2958 226.697 27.5521Z" fill="#009845"/>
        <path d="M236.959 10.1094H241.547V18.2589C242.603 16.9002 243.961 15.6611 246.285 15.6611C249.755 15.6611 251.777 17.9553 251.777 21.6681V32.1394H247.191V23.1163C247.191 20.9439 246.164 19.8267 244.414 19.8267C242.664 19.8267 241.547 20.9439 241.547 23.1163V32.1394H236.959V10.1094Z" fill="#009845"/>
        <path d="M251.649 9.07421H250.931V11.0137H250.756V9.07421H250.041V8.92432H251.649V9.07421Z" fill="#009845"/>
        <path d="M252.218 8.92432L252.988 10.7692L253.76 8.92432H253.999V11.0137H253.821V10.1051L253.834 9.17108L253.057 11.0137H252.921L252.146 9.17799L252.16 10.0981V11.0137H251.982V8.92432H252.218Z" fill="#009845"/>
        </svg>
    ) 
}
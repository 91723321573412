export const Facebook = ({ratio = 1}) => {
    return <svg width={35 * ratio} height={33 * ratio} viewBox="0 0 35 33" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path
            d="M34.6046 16.1622C34.6046 7.2361 27.0067 0 17.6343 0C8.26197 0 0.664062 7.2361 0.664062 16.1622C0.664062 24.2291 6.86988 30.9156 14.9827 32.128V20.834H10.6739V16.1622H14.9827V12.6014C14.9827 8.5508 17.5163 6.31335 21.3927 6.31335C23.2494 6.31335 25.1914 6.62901 25.1914 6.62901V10.6064H23.0516C20.9434 10.6064 20.2859 11.8523 20.2859 13.1305V16.1622H24.9925L24.2402 20.834H20.2859V32.128C28.3988 30.9156 34.6046 24.2292 34.6046 16.1622Z"
            fill="#1877F2"/>
        <path
            d="M24.2401 20.8342L24.9925 16.1623H20.2859V13.1306C20.2859 11.8523 20.9434 10.6066 23.0515 10.6066H25.1914V6.62914C25.1914 6.62914 23.2493 6.31348 21.3925 6.31348C17.5163 6.31348 14.9827 8.55093 14.9827 12.6016V16.1623H10.6738V20.8342H14.9827V32.1281C15.8599 32.259 16.7464 32.3247 17.6343 32.3245C18.5222 32.3247 19.4087 32.2591 20.2859 32.1281V20.8342H24.2401Z"
            fill="white"/>
    </svg>

}
import Carousel from "./Carousel";
import {About} from "./../about/About";
import {Insurance} from "./InsuranceComponent";
import {ServiceSummary} from "../service/ServiceSummary";
import './home.css';
import { Testimonies } from "../testimony/Testimonies";

export const Home = () => {
    return <div className="flex flex-col w-full mt-5 mr-10">
        <div className="grid grid-cols-1 md:gap-x-8 md:grid-cols-2 bg-slate-200 respPadding">
            <div className="flex-row place-items-start gap-y-10 mb-5">
                <div className="text-5xl font-semibold">
                    Sun’s Acupuncture
                </div>
                <div className="text-3xl pt-8">
                    Traditional Healing in a Modern Setting
                </div>
                <div className='text-2xl pt-12'>
                    We strive to improve the quality of your life, with trustworthy treatment and measurable results.
                </div>
                <div className='flex flex-row pt-20 gap-x-9 text-xl'>
                    <button
                        className='rounded-2xl bg-slate-500 hover:bg-slate-600 text-white pr-5 pl-5 pt-2 pb-2 border-0'
                        onClick={() => window.location.href = './book'}>Book
                        Now
                    </button>
                    <button className='rounded-2xl bg-slate-300 hover:bg-slate-400 pr-5 pl-5 pt-2 pb-2 border-0'
                            onClick={() => window.location.href = './about'}>About Us
                    </button>
                </div>
            </div>
            <Carousel/>
        </div>
        <div className='flex flex-row justify-center respPadding'>
            <Insurance/>
        </div>
        <div className="bg-new-beige respPadding">    
            <div>
                <div className='w-fit text-2xl rounded-3xl border-solid pl-8 pr-8 pt-3 pb-3'>
                    WHAT TREATMENTS DO WE OFFER?</div>
                <div className='pt-10 pb-20 flex flex-row justify-between w-full'>
                   <div><span className='text-3xl pt-10 pb-10 font-semibold'>Our Services</span></div>
                   <div><a className='viewMore text-2xl rounded-2xl bg-slate-600 pr-8 pl-8 pt-5 pb-5 text-white hover:text-white' href="./services">View all Services</a></div>
                </div>
                <ServiceSummary summary={true}/>
            </div>
        </div>
        <div className="bg-neutral-100 respPadding">
            <div className="grid grid-cols-1 md:grid-cols-3 pb-12">
                <div></div>
                <div className='md:col-span-2 w-fit text-2xl rounded-3xl border-solid pl-8 pr-8 pt-3 pb-3'>ABOUT Dr. Sun</div>
            </div>
            <About summary={true}/>
        </div>
        <div className="bg-white respPadding">
            <Testimonies />
        </div>
    </div>
}
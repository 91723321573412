import React from "react";
import Question from "./Question";

const questionAnswers = [
    {
        id: 0,
        title: 'What is Traditional Chinese Medicine?',
        content: 'Traditional Chinese Medicine (TCM) is a holistic medicine, which, like conventional medicine, aims to treat/prevent diseases and maintain a patient’s wellness. The techniquesused aim to help boost the body\'s own natural healing abilities. TCM is the crossroads between philosophy, science, nature and human beings. The harmony between nature and humans is very important for our well being. When a patient is treated with TCM, theirwhole body, mind, spirit, and emotions are taken into consideration to treat symptoms.'
    },
    {
        title: 'What is acupuncture?',
        content: 'Acupuncture is a system of integrative medicine that involves pricking the skin or tissues with very thin needles. It is used to alleviate pain and to treat various physical, mental,and emotional conditions. Chinese medicine and acupuncture work to treat the whole body, mind, spirit, and emotions to address the problem at its roots. Originating in ancient China, acupuncture is now widely practiced in the West.'
    },
    {
        id: 1,
        title: 'How does acupuncture work?',
        content: 'Acupuncture achieves the desired results by inserting needles into specific points that have the ability to alter biochemical and physiological conditions in the body, helping to improve one’s well being and treat illnesses. These points are called acupoints, which are found along the fundamental meridian pathways of the human body. The principle of acupuncture is to boost one’s life energy (Qi) and balance the Yin and Yang.'
    },
    {
        id: 2,
        title: 'What does acupuncture treat?',
        content: 'Acupuncture is usually thought of as a treatment for pain, such as back pain or headaches. While acupuncture is not only great for pain relief, it also successfully treats a variety of gynecological problems including menopause, PMS, infertility, nausea during pregnancy, as well as digestive problems, headaches, tinnitus, insomnia, stroke rehabilitation, Parkinson disease, emotional stress, anxiety, and more. According to the World Health Organization, acupuncture is proven to treat hundreds of health problems.'
    },
    {
        id: 3,
        title: 'Does acupuncture hurt?',
        content: 'The needles are very thin (0.14-0.22 mm) so people usually do not feel anything when they are inserted. After the needle is inserted, you may feel heaviness, soreness, coldness, or warmth under the needle area. This is called needle sensation, which indicates you have good Qi (life energy). In any case, if you were to experience any discomfort after the treatment, it is usually mild and short term. Because the purpose of acupuncture is to balance your body’s Yin and Yang, there are no long-term negative side effects. On the contrary, relaxation and a sense of well-being often occurs during treatment, causing some patients to even fall asleep.'
    },
    {
        id: 4,
        title: 'Are there risks or side effects of acupuncture?',
        content: 'The acupuncture needle is a fine, single-use, sterile, FDA-approved medical device. According to the National Institute of Health, relatively few complications occur from acupuncture.'
    },
    {
        id: 5,
        title: 'How many sessions do I need?',
        content: 'The amount of sessions you need is dependent on the duration, severity, and nature of your condition. Acute conditions such as a recent sprain or headache pain may only take one to three sessions. Chronic pain or internal health issues, such as indigestion, insomnia, stroke rehabilitation or infertility cannot be treated in one session. The effects of acupuncture are cumulative and you will feel progressively better with each session. For certain conditions, a better prediction of the number of sessions you might need will be made after you have already gone to a few,  since the way you respond to treatments will affect this number.'
    },
    {
        id: 6,
        title: 'How much does a session cost?',
        content: 'Acupuncture sessions range from $80-$120 (specific prices listed under "Appointments"). The first visit for a new patient will cost more than a follow-up session since it takes more time. During the first visit, the process includes an initial health evaluation, medical history consultation, and physical examination. Afterward, a diagnosis will be made and a treatment plan tailored to the patient’s unique situation will be determined. We will get started with this treatment plan at the first visit as well.',
        link: {
            href: 'price',
            info: 'Check out pricing under Appointments.'
        }
    },
    {
        id: 7,
        title: 'Do you offer free consultation?',
        content: 'Yes, we offer free 30 minute consultation.'
    },
    {
        id: 8,
        title: 'How long does a typical acupuncture session last?',
        content: 'The initial visit with consultation/evaluation and treatment varies from 90-120 minutes or longer, depending on the complexity of your health history. Regular follow-up sessions are 45-60 minutes long.'
    },
    {
        id: 9,
        title: 'Do you offer private treatment rooms for acupuncture?',
        content: 'Yes. All patients are treated in private rooms of about 120 square feet each. These spacious yet comfortable rooms include a large window, dimmable lights, and calming decor to provide a relaxing treatment experience.'
    },
    {
        id: 10,
        title: 'Is it ok if I fall asleep during a session?',
        content: 'Yes, it is ok to fall asleep during a session. In fact, most people do! If you’re having a session to treat insomnia, it is expected that you will fall asleep. Acupuncture is a wonderful way to reduce stress as well. It is recommended to use the remaining time during your treatment to rest quietly.'
    },
    {
        id: 11,
        title: 'What is cupping therapy?',
        content: 'Cupping therapy is another common technique in Traditional Chinese Medicine. The practitioner will put vacuumed cups, which are made from different materials such as glass, plastic or bamboo, on your back, shoulders or other areas of your body; they can remain stationary or be moved around. Cupping therapy can dredge meridians to relieve pain, expel pathogenic factors, or clear blood stasis to increase blood circulation. You may have seen some professional athletes with cupping marks as well.'
    },
    {
        id: 12,
        title: 'What is moxibustion therapy?',
        content: 'Moxibustion is an alternative therapy, commonly used in Traditional Chinese Medicine, that involves burning herbs and applying the resulting heat to specific points on the body. Typically administered in conjunction with acupuncture, moxibustion is very good for digestive issues, gynecological problems, and boosting immune functions after chemotherapy. There are two main types of moxibustion: direct and indirect. The technique most commonly used today, indirect moxibustion, often involves burning moxa (a substance created from dried leaves of the mugwort or wormwood herbs) on top of an acupuncture needle. In some cases, however, practitioners may set the burning moxa over a layer of ginger, garlic, or salt placed on the patient’s skin. Other techniques include applying heat to acupuncture points from an electrical source, as well as holding the burning moxa above the skin for several minutes.'
    },
    {
        id: 12,
        title: 'Is my treatment covered by insurance?',
        content: 'Dr. Sun is affiliated with Blue Cross Blue Shield of Michigan (BCBSM) as a healthcare provider, and we proudly participate in the BCBSM network. \n Chronic low back pain acupuncture treatments are also covered by Medicare. \n It is important, however, for you to review your specific insurance policy to confirm the extent of your medical coverage. \n' +
            '                        During your appointments, please be prepared to make payments for any required copayments, deductibles, and coinsurance as outlined in your insurance plan. \n' +
            '                        Your understanding and cooperation in this matter are greatly appreciated.' +
            'For individuals enrolled in Medicare Part B (Medical Insurance), the opportunity exists to receive as many as 12 acupuncture sessions within a 90-day span to address chronic low back pain. \n' +
            '                        In cases where substantial improvement is demonstrated, Medicare will extend coverage to an additional 8 sessions. \n' +
            '                        <br>\n' +
            '                        Over the course of a 12-month period, a maximum of 20 acupuncture sessions can be accessed. It is important to note that we are unable to directly process claims for your treatment. \n' +
            '                        To navigate the reimbursement process, please take the time to review your policy details and consider reaching out to Medicare directly. \n' +
            '                        <br>\n' +
            '                        For additional information and clarity on this matter, please consult the following resources:'
    }
];
export const FAQs = () => {
    return <div className="bg-slate-200 flex flex-col">
<div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
    <div className='pl-8 md:pl-36'>
        <div className='flex text-5xl text-gray-800 pt-24 pb-24'>
            <span>Frequently Asked Questions</span>
        </div>
        <div className='flex text-4xl text-gray-800 pr-10'>
            <span>If you have questions, we have answers for you. If you have further questions that are not addressed here, please feel free to reach out here. </span>
        </div>
        <div className='flex text-3xl text-gray-800 pt-20 font-bold'>
        <span>xiankuisun@gmail.com<br></br><br></br> 734-277-2342
        </span>
        </div>
    </div>
    <div className='md:col-span-2 p-8 md:pt-52 md:pl-20 md:pr-32 scroll-smooth'>
            {questionAnswers.map((qa) => (
                <div className='pt-8 pb-8'>
                    <Question id={qa.id} {...qa}/>
                </div>
            ))
            }
        </div>
</div>
</div>
}
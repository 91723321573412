export const Blog10 = () => {
    return (
        <div>
            Chemotherapy is a type of cancer treatment that uses powerful chemicals to kill fast-growing cells in your body. This works because cancer cells tend to grow and divide rapidly and uncontrollably. However, the body also includes numerous healthy cells that naturally grow at a fast pace. Chemotherapy treatments cannot differentiate between cancer cells and other fast-growing cells, which is why chemotherapy also harms or kills healthy cells.

            <br/><br/> Many side effects of chemotherapy are caused by the treatment’s impact on fast-growing healthy cells such as red blood cells, white blood cells, and hair cells, or cells that make up the mucus membranes of the mouth, throat, and digestive system. Common side effects of chemotherapy drugs include: nausea, vomiting, diarrhea, hair loss, loss of appetite, fatigue, fever, hot flash, mouth sores, pain, constipation, easy bruising, or bleeding. Many of these side effects can be prevented or treated. Most side effects subside after treatment ends. Chemotherapy drugs can also cause side effects that don't become evident until months or years after treatment. Late side effects vary depending on the type of chemotherapy drug but can include: damage to lung tissue, heart problems, infertility, kidney problems, or nerve damage (peripheral neuropathy).

            <br/><br/> Acupuncture is an integral component of Traditional Chinese Medicine (TCM) that originated more than 2,000 years ago. Treatment involves stimulation of one or more predetermined points on the body with needles, heat (moxibustion), pressure (acupressure), or electricity (electroacupuncture) for therapeutic effects through modulating Qi(energy) flow in meridians smoothly. Acupuncture has been evaluated in cancer patients (including chemotherapy patients) for control of various symptoms including pain, neurological side effects (neuropathy), fatigue, insomnia, and nausea. It is also recommended across various oncology supportive care guidelines.

            <br/><br/> Acupuncture is generally safe, well tolerated, and cost-effective. Some conditions can be treated quickly while others require continuous treatments in order to achieve long-term effects. 

            <br/>References:
            <div>
                <ol className="text-2xl">
                    <li>
                        Weidong Lu MB, et al. The Value of Acupuncture in Cancer Care. Hematology/Oncology Clinics of North America. Volume 22, Issue 4, August 2008, Pages 631-648
                    </li>
                </ol>
            </div>
        </div>
    );
}
export default Blog10
import { GoogleIcon } from "../svg/GoogleIcon";

const fiveStars = ()=> <div className='text-xl md:text-2xl font-semibold'>
        <span class="fa fa-star text-orange-400"></span>
        <span class="fa fa-star text-orange-400"></span>
        <span class="fa fa-star text-orange-400"></span>
        <span class="fa fa-star text-orange-400"></span>
        <span class="fa fa-star text-orange-400"></span>
    </div>;

const review = (msg)=>{return <div className='flex flex-col container gap-y-5 bg-stone-200 rounded-3xl p-12 pt-5 pb-5'>
    {fiveStars()}
        <div className='text-2xl'>{msg.fullname}</div>
        <div className='text-lg'>{msg.msg}</div>
    </div>};

const messages =[
    {fullname:"Chris DeGetmon", msg:"have been using acupuncture treatments for fifty years. Dr. Sun is by far the best that I can remember! Unlike most people practicing in the West,  Dr. Sun is a traditionalist using methods that can only be found in China. He places the needle's depth to insure maximum effectiveness. Most acupuncturists do not do this because it stimulates the points that might be painful; but without the depth to insure the gates are open fully to activate the Chi; whereas, most Westerners are not versed in the more powerful methods leading to energy gates being opened fully but instead open them only half way, and thereby less effective. What this means is that you are getting more bang for your dollar with Dr. Sun. Along with more powerful effective use of the treatment to impact your issues. Besides the methods, Dr. Sun is a very genuine human being who cares about his clients and their medical health. He is a 'kind' man and takes time to explain the treatments and listen to his clients. I give him my highest recommendation."},
    {fullname: 'Rhonda', msg: "Environment is very inviting, clean and calming. The Dr. Is very personable and patient.  He took the time to explain how acupuncture interacts with the different body systems/ meridians.  He was very patient with my mom who came with me. She's very inquisitive! He allowed for an understanding of how you are feeling after the initial needle. I'm assuming to ascertain your tolerance level. I find that I'm sleeping better and have less anxiety.  I was getting 2 to 3 hours of sleep at night and now I get about 5 within about a months time. I look forward to increasing the number of hours of sleep as I continue to have acupuncture."},
    {fullname: 'Andrea Jurk', msg: 'Dr. Sun is awesome! I went to see him, with open-mindedness and smidge of skepticism due to a prior experience. Dr. Sun took the time to fully understand my needs and fully explained the process in a way that was easy to understand. His practice is peaceful and relaxing! Instead of being anxious, I have been relaxed enough to fall asleep during my treatments. I am extremely grateful to Dr. Sun and highly recommend him to anyone looking for relief through acupuncture!'},
    {fullname: 'Kelly S.', msg: 'Highly recommend. Dr. Sun takes his time to make sure you’re comfortable, understand completely his procedure before proceeding. Spends as much time as you the patient needs. No gimmicks, no packages, this is real Chinese Medicine. He does not want to see you once a week for months. His goal to get you better and gone. His goal is to really address and fix your issue! Thank you Dr. Sun, you really addressed my issue and pain. I am pain free. I have been to acupuncture before but never like this, others wanted to drag out visits for months. I am very grateful.'},
];

export const Testimonies = () => {
 return <div className="flex flex-col justify-center gap-y-12">
            <div className='w-fit text-2xl rounded-3xl border-solid pl-8 pr-8 pt-3 pb-3'>LOVED BY OUR COMMUNITY</div>
            <div className="flex flex-col gap-y-3">
                <div className='flex flex-row justify-between w-full'>
                    <span className='text-3xl font-semibold'>Patient Testimonies</span>
                    <div className="flex flex-col gap-y-3 place-items-end">
                        <div className="flex flex-row gap-x-3 place-items-center text-2xl">
                            <span>Rated on</span>
                                <GoogleIcon />
                            </div>
                            <div className="flex flex-row gap-x-3 place-items-center text-2xl">
                                <div className='text-2xl font-semibold'><span class="fa fa-star text-orange-400"></span></div>
                                <span className='text-2xl'>5.0</span>
                            </div>
                        </div>
                    </div>
                </div>
        
            <div className='grid grid-cols-1 gap-y-5 md:grid-cols-2 lg:grid-cols-4 gap-x-3 md:gap-x-12 text-xl'>
                {messages.map((msg)=><div className='text-lg'>{review(msg)}</div>)}
            </div>  
    </div>
}
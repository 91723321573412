export const Appointment = () => {
    return <div className='flex flex-col justify-center bg-slate-200'>
        <div className='flex justify-center pt-12 pb-12 pl-12 pr-12 text-3xl '>
            Please call 734-277-2342 to make an appointment.
        </div>
        <div className='
            grid grid-cols-2 p-12 bg-white text-center justify-between text-xl font-semibold
            '>
            <div>
                <ul className="divide-y divide-dashed divide-x-0 list-none">
                    <li className="bg-slate-300 text-2xl pt-5 pb-5">First Visit</li>
                    <li className='pt-5 pb-5'>90 minute session*</li>
                    <li className='pt-5 pb-5'>Regular: $120</li>
                    <li className='pt-5 pb-5'>Members: $100**</li>
                    <li className='pt-5 pb-5'>Seniors 65+: $100</li>
                    <li className='pt-5 pb-5'>Veterans & Active Military: $100</li>
                </ul>
            </div>
            <div>
                <ul className="divide-y divide-dashed divide-x-0 list-none">
                    <li className="bg-slate-300 text-2xl pt-5 pb-5">Follow-Up Visit</li>
                    <li className='pt-5 pb-5'>60 minute session</li>
                    <li className='pt-5 pb-5'>Regular: $90</li>
                    <li className='pt-5 pb-5'>Members: $80**</li>
                    <li className='pt-5 pb-5'>Seniors 65+: $80</li>
                    <li className='pt-5 pb-5'>Veterans & Active Military: $80</li>
                </ul>
            </div>
        </div>
        <div className='flex flex-col text-lg pl-12 pr-12 md:pl-32 md:pr-32 pt-8 pb-8'>
            <div>Note: prices only apply for non-insured treatments.</div>
            <div>* The first visit includes initial evaluations as well as a treatment session.</div>
            <div>** Members of the Health & Fitness Center at WCC gain automatic membership.</div>
            <div>*** Free 30-minute Consultation</div>
        </div>
        <div className='flex justify-center text-2xl text-center bg-white pt-8 pb-16'>
            <div className='flex-col'>
                <div className='text-3xl pb-16'>Office Hours</div>
                <div>9:00 AM - 7:00 PM Monday through Saturday</div>
                <div>Closed Sunday</div>
            </div>
        </div>
    </div>
}
export const Blog7 = () => {
    return (
        <div>
            Bell's Palsy, also known as idiopathic facial palsy, is a form of temporary facial paralysis or weakness on one side of the face. It results from the dysfunction - often swelling and inflammation - of cranial nerve VII (facial nerve) which directs the
            muscles on one side of the face. These muscles control closing your eyes, blinking, and facial expressions such as smiling. The facial nerve is also responsible for many tasks such as controlling the tear glands, saliva glands, and muscles
            of a small bone in the middle of the ear. It also transmits taste sensations from the tongue.

            <br/><br/> Although Bell’s Palsy is the most common cause of facial paralysis, the exact cause of Bell’s Palsy is still unknown. Most scientists believe that reactivation of an existing (but dormant) viral infection may cause the disorder. Impaired
            immunity from stress, sleep deprivation, physical trauma, minor illnesses, or autoimmune syndromes are suggested as the most likely triggers. As the facial nerve swells and becomes inflamed in reaction to infections, it causes pressure within
            the Fallopian canal (a bony canal through which the nerve travels to the side of the face), leading to the restriction of blood and oxygen to the nerve cells. In some mild cases where recovery is rapid, there is damage only to the myelin sheath
            (the fatty covering that acts as insulation of nerve fibers).

            <br/><br/> Generally, Bell's Palsy affects only one side of the face; however, in rare cases, it can affect both sides. Symptoms will appear suddenly over a 48 - 72-hour period. They can generally start to improve with or without treatment after
            a few weeks, with the recovery of some or all facial functions occurring within six months. In some cases, residual muscle weakness may last longer or even be permanent.


            <br/><br/> <b>From the view of Traditional Chinese Medicine (TCM),</b> Bell’s Palsy is caused by a ‘wind-cold’ invasion. When extreme cold or wind hits a person’s unprotected face, it can cause the flow of Qi and Blood in the meridians on the
            face to slow down and even stop. This blocks the circulation of Qi and Blood in the meridians, resulting in a loss of feeling and muscular movement.


            <br/><br/> <b>Acupuncture</b> can dredge the channels on the body, especially on the face, and boost the Qi and Blood so they flow smoothly in the meridians again. Personalized acupoint combinations will be created for the treatment according
            to the differentiation of diagnosis. Moxa or electro-acupuncture is also used to help strengthen the treatments. Clinical data shows that acupuncture is widely used to effectively treat Bell’s Palsy.


            <br/><br/> <b>It is also important</b> to protect the face from further exposure to the cold and wind, eat nutritious meals, sleep early, avoid stress, and regularly massage your face at home to try and remove the cold and stimulate the facial
            muscles.

            <br/><br/>References:
            <ol className="list-disc">
                <li> <b>Efficacy of Acupuncture for Bell’s Palsy: A Systematic Review and Meta-Analysis of Randomized Controlled Trials.</b> Pingping Li, Tangmeng Qiu, and Chao Qin PMCID: PMC443184, PMID: 25974022
                </li>
                <li> <b>Effect of acupuncture in a patient with 7-year-history of Bell's palsy.</b> Chau Lai Wong, Virginia C N Wong. J Altern Complement Med. 2008 Sep;14(7):847-53.
                </li>
                <li> <b>The Efficacy of Acupuncture in the Treatment of Bell's Palsy Sequelae</b> Canan Ertemoğlu Öksüz, AhmetKalaycıoğlu, ÖzlemUzun, Sahi NurKalkısım, Nihat BurakZihni, Ahmet Yıldırım, CavitBoz. Journal of Acupuncture and Meridian Studies.
                    2019, Volume 12, Issue 4, 122-130
                </li>
                <li>
                    <a href="https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Fact-Sheets/Bells-palsy-Fact-Sheet" target="_blank" className="text-2xl text-blue-500" rel="noreferrer">https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Fact-Sheets/Bells-palsy-Fact-Sheet</a>
                </li>
            </ol>
        </div>
    );
}
export default Blog7
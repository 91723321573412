export const Gmail = ({ratio = 1}) => {
    return <svg width={42 * ratio} height={30 * ratio} viewBox="0 0 42 30" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.94633 29.4667V14.2906L5.01876 9.98481L0.600098 7.5954V26.7885C0.600098 28.2704 1.85709 29.4667 3.40403 29.4667H9.94633Z"
            fill="#4285F4"/>
        <path
            d="M32.3777 29.4666H38.92C40.4716 29.4666 41.7239 28.2658 41.7239 26.7884V7.59546L36.7191 10.3322L32.3777 14.2905V29.4666Z"
            fill="#34A853"/>
        <path
            d="M9.94663 14.2909L9.27612 8.36113L9.94663 2.68579L21.162 10.7202L32.3775 2.68579L33.1275 8.05472L32.3775 14.2909L21.162 22.3252L9.94663 14.2909Z"
            fill="#EA4335"/>
        <path
            d="M32.3777 2.68589V14.291L41.7239 7.59575V4.0249C41.7239 0.713048 37.7658 -1.17495 34.9948 0.811244L32.3777 2.68589Z"
            fill="#FBBC04"/>
        <path
            d="M0.600098 7.59558L4.89861 10.675L9.94633 14.2908V2.68572L7.32922 0.811071C4.5534 -1.17528 0.600098 0.712873 0.600098 4.02457V7.59558Z"
            fill="#C5221F"/>
    </svg>

}
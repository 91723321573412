export const Blog2 = () => {

    return (
        <div>
            Migraine is such a familiar word, because you, your friends, your family members, or coworkers may suffer
            from it. There are more than thirty-nine thousands papers related to migraine in PubMed.

            <br/><br/>
            According to the US National Center for Health Statistics, approximately 1 out of 7 Americans is affected by
            migraine headaches, and migraine treatment is a leading cause of emergency department and outpatient visits.

            <br/><br/>
            Scientists and physicians have been trying to find the cause and efficient treatment methods for migraine
            headaches. Many medications and preventive strategies have been developed, however the side effects of
            medications, such as fatigue,
            sleep interference, gastrointestinal effects, weight gain, and medication overuse, are sometimes even worse
            than a migraine.

            <br/><br/>
            Acupuncture is a common treatment for migraines in China. It is used for acute treatment and prevention of
            migraines. Many research and clinical papers about acupuncture and migraines have been published.

            <br/><br/>
            Acupuncture was developed in ancient China, and involves piercing specific areas of the body with a needle
            to alleviate pain. According to the Chinese practice, there are 14 meridians along the body, and if there is
            a disturbance of
            energy along these meridians, pain or illness can ensue. According to the theory of traditional Chinese
            Medicine (TCM), when the meridians are blocked, or some part of the body is lacking of nourishment, it is
            likely that pain will develop.
            When you see an experienced acupuncturist, they will not ask questions related to your pain, but they will
            ask questions about your health conditions. The whole body will be treated to maintain homeostasis according
            to syndrome differentiation.
            Different acupuncture prescriptions (acupoint combinations) are used for each patient, which is one of the
            unique parts of TCM. It is a specialized treatment for each patient. Acupuncture can treat acute attack of
            migraine. In many cases,
            the pain can be much reduced or relieved immediately. Acupuncture is also used to prevent migraines by
            reducing episodic frequency.

            <br/><br/>
            How does acupuncture treat and prevent migraines?
            <br/><br/>
            <div>
                <ul className="list-disc">
                    <li>First of all, through acupoints stimulation, the blockage of meridians is removed and Qi (life
                        energy) is restored and released to move more smoothly within meridian channels.
                    </li>
                    <li>Secondly, new research shows that acupuncture at acupoints on the neck, or head, such as GV20
                        (DU20, Hundred Meetings, Baihui) increases blood flow to the br/ain without raising arterial
                        blood pressure or pulse rate. Researchers sought
                        to measure cerebr/al flow in an attempt to understand the mechanisms by which acupuncture
                        induces a therapeutic effect. GV20 is well regarded for it ability to treat disorders related to
                        the head and br/ain. Spasm of blood vessels
                        in the br/ain, or a decrease of the blood supply to the br/ain is one of the mechanisms of
                        migraine.
                    </li>
                    <li>Thirdly, acupuncture stimulation can increase the release of endogenous substances with a
                        morphine-like biological property, called endorphins, thus inhibiting pain perception.
                    </li>
                    <li>Lastly, acupuncture can release tightness of muscles around the neck and shoulders. In my
                        acupuncture clinic, during treatment patients will lay on the treatment bed in a quiet private
                        room while listening to relaxing music, entering
                        a state of profound relaxation, and leaving without pain and side effects.
                    </li>
                </ul>
            </div>

            <br/>
            References:
            <div>
                <ol className="list-disc">
                    <li>Acupuncture in Adult and Pediatric Headache: A Narrative Review.
                        <br/>Doll E, Threlkeld B, Graff D, Clemons R, Mittel O, Sowell MK, McDonald M.
                        <br/>Neuropediatrics. 2019 Aug 29. PMID:31466110
                    </li>
                    <li>Acupuncture versus propranolol in migraine prophylaxis: an indirect treatment comparison
                        meta-analysis.
                        <br/>Chen YY, Li J, Chen M, Yue L, She TW, Zheng H.
                        <br/>J Neurol. 2019 Aug 21. PMID:31435770
                    </li>
                    <li>Erratum to "Effect of Electroacupuncture on Hyperalgesia and Vasoactive Neurotransmitters in a
                        Rat Model of Conscious Recurrent Migraine".
                        <br/>Xu X, Liu L, Zhao L, Li B, Jing X, Qu Z, Zhu Y, Zhang Y, Li Z, Fisher M, Cairns BE, Wang L.
                        <br/>Neuropediatrics. 2019 Aug 29. PMID:31466110
                    </li>
                    <li>Evid Based Complement Alternat Med. 2019 Jul 14;2019:8181245. eCollection 2019.
                        <br/>PMID:31391861
                        <br/>Hyung-sik Byeon, Sang-kwan Moon, Seong-uk Park, Woo-sang Jung, Jung-mi Park, Chang-nam Ko,
                        Ki-ho Cho, Young-suk Kim and Hyung-sup Bae. The Journal of Alternative and Complementary
                        Medicine. March 2011, 17(3): 219-224.
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default Blog2



import { About } from "./About"
import img1 from './img/office1.gif';
import img2 from './img/office2.gif';
import img3 from './img/office3.gif';
import img4 from './img/office4.jpg';
import {UMMED} from './../svg/UMMED'
import {ECFMG} from './../svg/ECFMG'
import {NCCAOM} from './../svg/NCCAOM'
import {AIAM} from './../svg/AIAM'

export const AboutContainer = () => {
    return <div className="flex flex-col w-full">
            <div className="p-8 lg:pt-32 lg:pl-32 lg:pr-32 bg-neutral-100">
                <About summary={false}/>
            </div>
            <div className="w-full flex flex-row bg-white pt-16 pb-16 pl-12 pr-12 place-content-center gap-x-12 md:gap-x-8
            
             place-items-center">
                        <NCCAOM/>
                        <ECFMG/>
                        <AIAM/>
                        <UMMED/>
                    </div>
            <div className='w-full bg-new-beige grid grid-cols-4 p-8 gap-x-4 md:p-12 md:gap-x-8 lg:gap-x-24 lg:p-32 content-start'>
                <img src={img1} className="w-full rounded-2xl" alt=''/>
                <img src={img2} className="w-full rounded-2xl" alt=''/>
                <img src={img3} className="w-full rounded-2xl" alt=''/>
                <img src={img4} className="w-full rounded-2xl" alt=''/>
            </div>
        </div>
}
import {InsuranceProviders} from "./InsuranceProviders";

export const InsurancePage = () => {

	return (
		<div className="min-h-screen flex flex-col">
			{/* Top Section */}
			<div className="flex-grow bg-blue-100 p-8">
                <div className="flex flex-col md:flex-row justify-between items-start gap-8">
                    {/* Left side - Title */}
                    <div className="flex justify-center md:justify-start w-full md:w-2/5 h-full mt-12 flex-shrink-0">
                        <p className="md:ml-28 text-5xl font-bold">Insurance</p>
                    </div>
                    
                    {/* Right side - Insurance Provider Grid */}
                    <div className="w-full md:w-2/3">
                        <div className="flex justify-center md:justify-start">
                            <p className="md:mt-14 text-2xl">Let's get you covered! <br/><br/> Select your insurance provider for more information on acunpuncture coverage: </p>
                        </div>
                        <InsuranceProviders />                    
                    </div>
                </div>
			</div>


			{/* Bottom Section */}
			<div className="flex flex-row place-content-center bg-white p-8 md:p-24 lg:pr-32 lg:pr-32">
				<div className="p-8">
					<div className="text-2xl space-y-4">
						<p className="leading-none">
							Disclaimer: Please review your specific insurance policy to confirm the extent of your medical coverage. 
							During your appointments, please be prepared to make payments for any required copayments, deductibles, 
							and coinsurance as outlined in your insurance plan. Your understanding and cooperation in this matter are 
							greatly appreciated.
						</p>
						<p className="leading-none">
							**Medicare Coverage for Acupuncture: For individuals enrolled in Medicare Part B (Medical Insurance), 
							the opportunity exists to receive as many as 12 acupuncture sessions within a 90-day span to address 
							chronic low back pain. In cases where substantial improvement is demonstrated, Medicare will extend 
							coverage to an additional 8 sessions. Over the course of a 12-month period, a maximum of 20 acupuncture 
							sessions can be accessed. It is important to note that we are unable to directly process claims for your treatment.
						</p>
						<p className="leading-none">
							To navigate the reimbursement process, please take the time to review your policy details and consider 
							reaching out to Medicare directly.
						</p>
					</div>
				</div>
			</div>
		</div>
    );
  };
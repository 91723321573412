export const GoogleIcon = ({ratio = 1}) => {
    return <svg width="63" height="21" viewBox="0 0 63 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60.4885 12.5066L62.2231 13.663C61.6601 14.4922 60.3136 15.9149 57.9856 15.9149C55.0947 15.9149 52.9418 13.6782 52.9418 10.8329C52.9418 7.80508 55.1175 5.75098 57.7422 5.75098C60.382 5.75098 61.6753 7.85064 62.0938 8.98418L62.322 9.56243L55.5208 12.3772C56.0381 13.3967 56.8446 13.914 57.9856 13.914C59.1268 13.914 59.9179 13.3511 60.4885 12.5066ZM55.1556 10.6733L59.6974 8.78646C59.4463 8.15509 58.7008 7.70629 57.8106 7.70629C56.6771 7.70629 55.1024 8.7105 55.1556 10.6733Z" fill="#FF302F"/>
    <path d="M49.6629 0.570312H51.8538V15.451H49.6629V0.570312Z" fill="#20B15A"/>
    <path d="M46.209 6.14727H48.3239V15.1852C48.3239 18.9357 46.1101 20.4801 43.493 20.4801C41.0282 20.4801 39.5448 18.8217 38.9893 17.4751L40.9293 16.6686C41.2791 17.4979 42.1236 18.4794 43.493 18.4794C45.1743 18.4794 46.209 17.437 46.209 15.4896V14.7593H46.1329C45.6308 15.3678 44.6722 15.9155 43.455 15.9155C40.914 15.9155 38.586 13.7017 38.586 10.8489C38.586 7.9807 40.914 5.74414 43.455 5.74414C44.6646 5.74414 45.6308 6.28429 46.1329 6.87769H46.209V6.14727ZM46.361 10.8489C46.361 9.05343 45.1667 7.74488 43.6452 7.74488C42.1084 7.74488 40.8151 9.05343 40.8151 10.8489C40.8151 12.6214 42.1084 13.9071 43.6452 13.9071C45.1668 13.9148 46.3612 12.6214 46.3612 10.8489" fill="#3686F7"/>
    <path d="M26.5583 10.8102C26.5583 13.7392 24.2761 15.8921 21.4764 15.8921C18.6769 15.8921 16.3945 13.7316 16.3945 10.8102C16.3945 7.86604 18.6769 5.7207 21.4764 5.7207C24.2761 5.7207 26.5583 7.86604 26.5583 10.8102ZM24.3369 10.8102C24.3369 8.98444 23.0131 7.72901 21.4764 7.72901C19.9397 7.72901 18.6159 8.98444 18.6159 10.8102C18.6159 12.6208 19.9397 13.8913 21.4764 13.8913C23.0132 13.8913 24.3369 12.6208 24.3369 10.8102Z" fill="#FF302F"/>
    <path d="M37.6579 10.8329C37.6579 13.7619 35.3756 15.9148 32.576 15.9148C29.7764 15.9148 27.4941 13.7618 27.4941 10.8329C27.4941 7.88874 29.7764 5.75098 32.576 5.75098C35.3756 5.75098 37.6579 7.88117 37.6579 10.8329ZM35.4288 10.8329C35.4288 9.00714 34.1051 7.75172 32.5683 7.75172C31.0317 7.75172 29.7079 9.00714 29.7079 10.8329C29.7079 12.6435 31.0317 13.914 32.5683 13.914C34.1127 13.914 35.4288 12.6359 35.4288 10.8329Z" fill="#FFBA40"/>
    <path d="M8.04131 13.6863C4.85365 13.6863 2.35844 11.1149 2.35844 7.92724C2.35844 4.73971 4.85365 2.16829 8.04131 2.16829C9.76067 2.16829 11.0159 2.84534 11.944 3.71266L13.4732 2.18356C12.1799 0.943522 10.453 0 8.04131 0C3.67456 0.000122123 0 3.56062 0 7.92724C0 12.2941 3.67456 15.8545 8.04131 15.8545C10.3997 15.8545 12.1799 15.0785 13.5721 13.6331C15.0023 12.2028 15.4435 10.1943 15.4435 8.56631C15.4435 8.05657 15.3827 7.53169 15.3142 7.1437H8.04131V9.25863H13.2221C13.07 10.5824 12.6516 11.4877 12.0353 12.1038C11.2898 12.8571 10.1107 13.6863 8.04131 13.6863Z" fill="#3686F7"/>
    </svg>
}    

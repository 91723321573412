export const Blog9 = () => {
    return (
        
        <div>
            If you and your partner are struggling to have a baby, you are not alone. In the United States, 10% to 15% of couples are infertile.

            <br/><br/> Infertility is a disease of the male or female reproductive system defined by the failure to achieve a pregnancy after 12 or more months of regular, unprotected sexual intercourse. Infertility can be primary or secondary. Primary infertility is when a pregnancy has never been achieved by a person, and secondary infertility is when at least one prior pregnancy has been achieved. 

            <br/><br/> In the male reproductive system, infertility is most commonly caused by problems in the ejection of semen, absence or low levels of sperm, or abnormal shape (morphology) and movement (motility) of the sperm. In the female reproductive system, infertility may be caused by abnormalities of the ovaries, uterus, fallopian tubes, and the endocrine system, among others.

            <br/><br/> In addition to the above reasons, age and stress are other factors affecting pregnancy. In modern society, people are becoming more and more busy (with work, life, etc.) such that many couples are not ready to tackle pregnancy and parenting in their 20s or even early 30s. High levels of pressure and stress from these busy lives can also negatively affect the success of pregnancy. 

            <br/><br/> According to the theory of Traditional Chinese Medicine, female infertility can be classified as the following: Kidney deficiency, Spleen deficiency, stagnation of Liver Qi, static blood blocking in the uterine, and accumulation of phlegm or coldness in the uterine. You may have the following symptoms: irregular period, painful period, headache, dizziness, insomnia, low appetite, abdominal bloating, coldness in your lower abdomen, coldness in your hands and feet, short temper, etc.

            <br/><br/> Acupuncture treats infertility by balancing the Yin and Yang of your body, soothing the Liver Qi, boosting Qi to flow smoothly in the meridians, warming up the uterine, increasing blood flow to the uterine, balancing levels of hormones, and calming your mind and spirit. Acupuncture treatments will be personalized according to your situation. After the treatments, your whole body and mind will be ready for you to have a baby.

            <br/><br/> According to clinical studies, acupuncture can also increase the successful rate of IUIs and IVFs. More information on infertility can be found through the World Health Organization’s site linked below.

            <br/><br/>
            <ul>
            <ol>
            <li>
                <a href="https://www.who.int/news-room/fact-sheets/detail/infertility" 
                target="_blank" className="text-2xl text-blue-500" rel="noreferrer">World Health Organization: Infertility
                </a>
                </li>
                </ol>
            </ul>
        </div>
    );
}
export default Blog9
// #Announcement (Health Insurance)
import React from "react";
import {insuranceList} from "../insurance_page/InsuranceProviders";

export const Insurance = () => {
    return <div className="flex flex-col place-items-center bg-white">
        <div className='
            flex flex-col w-full divide-y divide-x-0 divide-solid divide-slate-400'>    
            <div className='flex-col'>
                <div className='text-3xl font-semibold w-full'>
                    Your health insurance could cover your treatment!
                </div>
                <div className='pt-12 pb-12 text-2xl lg:w-full md:w-full sm:w-full'>
                    Now a healthcare provider within the Blue Cross Blue Shield of Michigan (BCBSM) network. Chronic low
                    back pain acupuncture treatments are also covered by Medicare.
                </div>
            </div>
            <div className="flex place-content-evenly pt-12">
                <div className="grid grid-cols-3 lg:grid-cols-6 justify-center w-full">
                        {insuranceList.map((provider) => (
                            <div
                                key={provider.name}
                                className="w-5/6 bg-white flex items-center justify-center border-none">
                                {provider.logo}
                            </div>
                        ))}
                    </div>
            </div>
        </div>
    </div>
}
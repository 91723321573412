import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {LeftBar} from './components/navigation/leftnav/LeftBar'
import {NavigationLinks} from "./components/navigation/Navigation";
import './Content.css'; // Import the CSS file
import './components/navigation/leftnav/LeftBar.css';
import useScript from './helpers/UseScript';
import {AboutContainer} from './components/about/AboutContainer';
import {Appointment} from './components/appointment/Appointment';
import {Blogs} from './components/blogs/Blogs';
import {BlogPage} from './components/blogs/BlogPage';
import {Contact} from './components/contact/Contact';
import {Home} from './components/home/Home';
import {FAQs} from './components/qa/FAQs';
import {InsurancePage} from './components/insurance_page/InsurancePage'
import {Services} from './components/service/Services';
import logo from './img/logo-s-no-w.gif';
import {Footer} from "./components/footer/Footer";

const Content = () => {
    // use fontawesome
    useScript('https://kit.fontawesome.com/c2f298ce28.js');

     const toggleBurgerMenu =(e)=>{
        const burger = document.getElementsByClassName("burgerContainer");
        burger[0].classList.toggle("change");
        
        const menuLeft = document.getElementsByTagName('aside');
        menuLeft[0].classList.toggle("cbp-spmenu");
        menuLeft[0].classList.toggle("leftNavBar");
        menuLeft[0].classList.toggle("invisible");
    }

    return (
        <BrowserRouter>
            <React.Fragment>
                <header >
                    <div className="HorizontalContainer">
                        {/*Top bar for navigation */}
                        <div className='flex flex-row w-full gap-x-6 pl-8 pr-8 lg:pl-20 lg:pr-20 items-center'>
                            <div className="lg:w-1/6 flex flex-row flex place-content-start md:w-1/4 sm:w-1/4 min-[320px]:w-1/2">
                                <span className='text-5xl font-bold'><img src={logo} alt="logo" className='mr-8' />ReQi</span>
                            </div>
                            <div
                                className="flex flex-row lg:w-5/6 lg:visible md:w-0 md:invisible sm:w-0 sm:invisible min-[320px]:w-0 min-[320px]:invisible">
                                <div className="w-5/6 lg:pr-20">
                                    <NavigationLinks/>
                                </div>
                                <div className='w-1/6 bg-slate-600 rounded-2xl text-white'>
                                    734-277-2342
                                </div>
                            </div>
                            <div className='flex place-content-end lg:w-0 lg:invisible md:w-3/4 md:visible sm:w-3/4 sm:visible min-[320px]:w-3/4 min-[320px]:visible'>
                                <a className='self-center mr-4' href='/book'>Book Now</a>
                                <div className="burgerContainer" onClick={(e)=>toggleBurgerMenu(e)}>
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Left Section (LeftBar, for example) */}
                <LeftBar onToggle={toggleBurgerMenu}/>

                {/* Center Section (Main Content) */}
                <main className='flex-1 mt-36'>
                    <div className="font-sans text-gray-800 subpixel-antialiased">
                        <Routes>
                            <Route path="/" exact element={<Home/>}/>
                            <Route path="/about" element={<AboutContainer/>}/>
                            <Route path="/blogs" element={<Blogs/>}/>
                            <Route path="/blogs/:id" element={<BlogPage/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/book" element={<Appointment/>}/>
                            <Route path="/qa" element={<FAQs/>}/>
                            <Route path="/services" element={<Services/>}/>
                            <Route path="/insurance" element={<InsurancePage/>}/>
                        </Routes>
                    </div>
                </main>
                <Footer/>
            </React.Fragment>
        </BrowserRouter>

    );
};

export default Content;

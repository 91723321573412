export const Blog4 = () => {
    return (
        <div>
            Psoriasis is a chronic, immune-mediated, relapsing-remitting inflammatory dermatosis triggered by multiple environmental and endogenous factors in genetically susceptible individuals. Clinically, psoriasis usually appears as recurrent episodes of well-demarcated,
            scaly erythematous plaques and, in rare cases, it can manifest as generalized life-threatening erythroderma. Histologic features that characterize psoriasis include acanthosis, which is reflective of a state of keratinocyte hyperproliferation,
            and parakeratosis, which is indicative of dysregulated keratinocyte differentiation. Another characteristic of this disease is increased vascularization, which allows the accumulation of inflammatory subpopulations of neutrophils, dendritic
            cells, and T lymphocytes. Recent research has revealed that psoriasis may be related to the leaky gut syndrome, also known as increased intestinal permeability. This syndrome occurs when the lining of the intestines becomes damaged. Due to
            this damage, the lining is unable to prevent waste products from leaking out into the bloodstream. Such waste can include bacteria, toxins, and undigested food. Maintaining a healthy diet and consuming probiotics may help psoriasis patients.

            <br/><br/>
            In traditional Chinese medicine, from “syndrome differentiation of blood aspect”, psoriasis is considered to be caused by blood heat, dampness heat, blood dryness, toxins in the blood, and blood stasis. Psoriasis may also be caused
            by impairment of the Liver and Kidneys, or by disharmony between the Penetrating (Chong) and Conception (Ren) meridians, between the Yin and the blood, or between the Yin and Yang in the Zangfu. Acupuncture, through stimulating acupoints by
            needles, can clear blood heat, nourish the blood, remove stasis and detoxify the blood, make Qi flow smoothly in the meridians, and balance Yin and Yang of the body.

            <br/><br/>
            Acupuncture is becoming more and more popular and effective in the treatment of psoriasis. Remember that Psoriasis is a chronic disease: it requires care and patience in treating it. Avoiding stressful situations and maintaining a
            healthy diet is very important.

            <br/><br/>
            References:
            <ul className="list-disc">
                <ol>
                    <li>Meng S, et al. <b>Psoriasis therapy by Chinese medicine and modern agents.</b> Chin Me. 2018; 13:16 PMID 29588654.PMCID: PMC5865286
                    </li>
                    <li>Dalal. I, et al. <b>The role of gut microbiome in the pathogenesis of psoriasis and the therapeutic effects of probiotics.</b> J Family Med Prim Care. 2019 Nov; 8(11):3496-3503. PMID: 31803643.
                    </li>
                </ol>
            </ul>

        </div>
    );
}
export default Blog4
import {useParams, Navigate} from "react-router-dom";
import {blog_dict} from './blog_list.js'

export const BlogPage = () => {
    let params = useParams()
    let id = params.id

    if (id in blog_dict) {
        return (
            <div className="w-full pb-50 bg-[#f9f9f9]">
                <div className="max-w-[1240px] mx-auto place-items-center">
                    <div
                        className="flex ml-16 mr-20 overflow-y-scroll gap-x-9 gap-y-14 bg-white">
                        <div className="px-16 pb-20">
                            <img src={blog_dict[id][2]} className="w-full object-cover" alt=''/>
                            <h1 className="font-bold text-3xl my-1 pt-5 text-black">{blog_dict[id][1]}</h1>
                            <div className="pt-5">
                                {blog_dict[id][0]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Navigate to="/blogs" replace={true}/>
    }
};

export default BlogPage

import React from 'react';
import { ServiceSummary } from './ServiceSummary';

export const Services = (props) => {
    return <div className="componentBox grid bg-stone-200">
        <div className="container">
            <div className="row text-center">
                <div className="col-md-8 col-md-offset-2 mt-16 mb-12">
                    <div className='text-5xl'>Services</div>
                </div>
                <div className="row text-center">
                    <div className="col-md-8 col-md-offset-2 ">
                    </div>
                </div>
            </div>
        </div>
        <div className='px-8 py-8 md:px-20 md:py-20'>
            <ServiceSummary summary={false}/>
       </div>
        <div>
            <div className="container">
                <div className="col-md-13 service-div treatment-summary">
                    <h2>Commonly Treated Conditions</h2>
                    <p>
                        Commonly treated conditions are including but not limited to the following conditions:
                    </p>
                </div>
            </div>
            <div className="container text-left">
                <div className="grid grid-cols-1 space-y-8 space-x-0 md:grid-cols-4 md:space-x-5 md:space-y-0 text-3xl pb-8">
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Gynecology/Obsterics</div>
                        <ul className='text-2xl'>
                            <li>Infertility</li>
                            <li>IVF (In vitro fertilization) supporting treatments</li>
                            <li>Endometriosis</li>
                            <li>Morning sickness</li>
                            <li>Period problems:</li>
                            <li>Premenstrual syndrome</li>
                            <li>Promoting lactation</li>
                        </ul>
                        <br/>
                    </div>
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Respiratory problems</div>
                        <ul className='text-2xl'>
                            <li>Asthma</li>
                            <li>Chronic cough</li>
                            <li>Sinusitis and rhnositis</li>
                            <li>Seasonal allergy</li>
                            <li>Sore throat</li>
                        </ul>
                    </div>
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Calming the Mind</div>
                        <ul className='text-2xl'>
                            <li>Anxiety</li>
                            <li>ADHD</li>
                            <li>Depression</li>
                            <li>Eating disorders</li>
                            <li>Insomnia</li>
                            <li>Panic attacks</li>
                            <li>PTSD</li>
                            <li>Stress</li>
                        </ul>
                    </div>
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Other system problems</div>
                        <ul className='text-2xl'>
                            <li>Acute lumbar muscle sprain</li>
                            <li>High blood pressure</li>
                            <li>Itchy skin</li>
                            <li>Psoriasis</li>
                            <li>Shingles</li>
                            <li>Restless leg syndrome</li>
                        </ul>
                    </div>
                </div>
                <div className="grid grid-cols-1 space-y-8 space-x-0 md:grid-cols-4 md:space-x-5 md:space-y-0 text-3xl pb-8">
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Pain Management</div>
                        <ul className='text-2xl'>
                            <li>Chronic back pain</li>
                            <li>Headaches: Tension, migraine, and cluster headaches</li>
                            <li>Fibromyalgia</li>
                            <li>General joint pain</li>
                            <li>Shoulder pain: Frozen shoulder, rotator cuff injuries</li>
                            <li>Shoulder pain: Tennis elbow, Golf elbow</li>
                            <li>Myofascial pain syndrome</li>
                            <li>Pain caused by cancer or by cancer treatment</li>
                        </ul>
                    </div>
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Neurological Conditions</div>
                        <ul className='text-2xl'>
                            <li>Bell’s palsy</li>
                            <li>Cerebral palsy</li>
                            <li>Carpal tunnel syndrome</li>
                            <li>Parkinson’s disease</li>
                            <li>Stroke rehabilitation</li>
                            <li>Tic disorders</li>
                            <li>Tinnitus</li>
                        </ul>
                    </div>
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Digestive Problems</div>
                        <ul className='text-2xl'>
                            <li>Constipation and Diarrhea</li>
                            <li>Crohn’s disease</li>
                            <li>Diabetics</li>
                            <li>Gastroesophageal Reflux Disease (GERD)</li>
                            <li>Hemorrhoids</li>
                            <li>Irritable bowel syndrome (IBS)</li>
                            <li>Ulcerative colitis</li>
                            <li>Vomiting and nausea</li>
                        </ul>
                    </div>
                    <div className="flex flex-col place-items-start bg-white">
                        <div className='text-3xl p-5'>Urinary problems</div>
                        <ul className='text-2xl'>
                            <li>Interstitial cystitis</li>
                            <li>Impotence</li>
                            <li>Prostate problems</li>
                            <li>Urinary incontinence & retention</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
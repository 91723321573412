export const Blog6 = () => {
    return (
        <div>
            Restless legs syndrome (RLS), also called Willis-Ekbom Disease, causes unpleasant or uncomfortable sensations in the legs and an irresistible urge to move them. Symptoms commonly occur in the late afternoon or evening hours, and are often most severe
            at night when a person is resting, such as sitting or lying in bed. This may also occur when someone is inactive and sitting for extended periods (for example, when taking a trip by plane or watching a movie). Since symptoms can increase in
            severity during the night, it could become difficult to fall asleep or return to sleep after waking up. Moving the legs or walking typically relieves the discomfort but the sensations often recur once the movement stops.

            <br/><br/> RLS is classified as a sleep disorder since the symptoms are triggered by resting and attempting to sleep. It is also classified as a movement disorder since people are forced to move their legs in order to relieve symptoms. It is,
            however, best characterized as a neurological sensory disorder with symptoms that are produced from within the br/ain itself.

            <br/><br/> RLS is one of several disorders that can cause exhaustion and daytime sleepiness, which can strongly affect mood, concentration, job and school performance, and personal relationships. Many people with RLS report they are often unable
            to concentrate, have impaired memory, or fail to accomplish daily tasks. Untreated moderate to severe RLS can lead to about a 20 percent decrease in work productivity and can contribute to depression and anxiety. It also can make traveling
            difficult.

            <br/><br/> It is estimated that up to 7-10 % of the U.S. population may have RLS. RLS occurs in both men and women, although women are more likely to have it than men. It may also begin at any age. Many individuals who are severely affected
            are middle-aged or older, and the symptoms typically become more frequent and last longer with age.

            <br/><br/> More than 80 percent of people with RLS also experience periodic limb movement of sleep (PLMS). PLMS is characterized by involuntary leg (and sometimes arm) twitching or jerking movements during sleep that typically occur every 15
            to 40 seconds throughout the night. Although many individuals with RLS also develop PLMS, most people with PLMS do not experience RLS.

            <br/><br/> In most cases, the cause of RLS is unknown. However, RLS has a genetic component and can be found in families where the onset of symptoms occurs before age 40. In addition, specific gene variants have been associated with RLS. Evidence
            indicates that low levels of iron in the br/ain may be responsible for RLS as well.

            <br/><br/> Considerable evidence also suggests that RLS is related to a dysfunction in the basal ganglia, one of the sections of the br/ain that controls movement and uses the neurotransmitter dopamine. Dopamine is needed to produce smooth, purposeful
            muscle activity and movement. Disruption of these pathways frequently results in involuntary movements. Individuals with Parkinson’s disease (another disorder of the basal ganglia’s dopamine pathways) have an increased chance of developing
            RLS.

            <br/><br/> From Traditional Chinese Medicine (TCM) theory, RLS is most related to the “Liver”, which controls contraction, tension, and strength of muscles and sinews. If muscles and sinews are not well nourished, RSL can occur. Acupuncture,
            as a treatment means of TCM, has been widely used for RLS management in Asian countries such as China, Japan, and Korea. Four gate points (LI-4 and Liv-3) combined with SP9, GB34 are commonly used. Other acupuncture points may also be used
            according to syndrome differentiation. An increasing number of studies have been published in recent years to support the effectiveness of acupuncture for RLS.

            <br/><br/>References:
            <ul className="list-disc">
                <ol>
                    <li> <b>A systematic review and meta-analysis protocol.</b> Huang, Kaiyu MM; Liang, Shuang MM; Han, Dong MM; Guo, Rubao MD; Chen, Lei MB; Grellet, Antoine MD. Medicine: January 2020 - Volume 99 - Issue 3 - p e18902
                    </li>
                    <li> <b>Acupuncture and related techniques for restless legs syndrome: A protocol for systematic review and meta-analysis.</b> Jie Xiang , Honglian Li , Jun Xiong , Fanghui Hua , Shouqiang Huang , Yunfeng Jiang , Hailiang Qiang , Fan Xie ,
                        Min Wang. Medicine (Baltimore), 2020 Sep 25;99(39).
                    </li>
                    <li>
                        <a href="https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Fact-Sheets/Restless-Legs-Syndrome-Fact-Sheet" target="_blank" className="text-2xl text-blue-500" rel="noreferrer">https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Fact-Sheets/Restless-Legs-Syndrome-Fact-Sheet</a>
                    </li>
                </ol>
            </ul>
        </div>
    );
}
export default Blog6
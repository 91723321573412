import sun2 from './img/Dr_sun-vertical.gif';
import './../../Content.css';
import './About.css';
import {useState} from "react";
import {Gmail} from "../svg/Gmail";
import {Facebook} from "../svg/Facebook";
import {Instagram} from "../svg/Instagram";
import {DoctorSVG} from './../svg/DoctorSVG'
import {Lamp} from './../svg/Lamp';
import {Brain} from './../svg/Brain';

const bioDescriptionSummary = 'Dr. Sun is a licensed acupuncturist, receiving his Master’s Degree in acupuncture from the American Institute of Alternative Medicine, and is certified by the National Certification Commission for Acupuncture and Oriental Medicine (NCCAOM). With his diverse qualifications, Dr. Sun incorporates both Western Medicine and Traditional Chinese Medicine in his treatments to bridge the gap between the philosophies, improving both doctor-patient communication and treatment effectiveness. He deeply understands the mechanisms of pain, depression, stress, strokes, brain paralysis and other problems related to the nervous system and truly hopes to help as many people as possible with his unique background.';

const bioDescription = {
    1: "Dr. Sun is a licensed acupuncturist, receiving his Master's Degree in acupuncture from the \
    American Institute of Alternative Medicine, and is certified by the National Certification \
    Commission for Acupuncture and Oriental Medicine (NCCAOM).",

    2: "Before acupuncture, Dr. Sun obtained his M.D. from China. After moving to Ann Arbor,    \
    Michigan, he passed all the exams of the USMLE (United States Medical Licensing Examination)    \
    and became certified by the ECFMG (Educational  \
    Commission For Foreign Medical Graduates). Dr. Sun obtained a Master's Degree in Human Anatomy  \
    through researching \
    pain-conducting pathways of the brain and a Ph.D. in neuroscience through researching   \
    the molecular mechanisms of pain and brain aging. He has many   \
    years of clinical experience and has been studying neuroscience for more than thirty    \
    years, with over twenty of them being at the University of Michigan Medical School. Dr. \
    Sun deeply understands the mechanisms of pain, depression,  \
    stress, strokes, brain paralysis and other problems related to the nervous system.",

    3: "With his M.D. combined with his Master's Degree in acupuncture, Dr. Sun \
    incorporates both Western Medicine and Traditional Chinese Medicine in his  \
    treatments to bridge the gap between the philosophies, which improves both  \
    doctor-patient communication and treatment effectiveness. He hopes to help as many  \
    people as possible with his unique background."
}

export const About = (props) => {
    const [summary] = useState(props.summary ?? false);
    return <div>
            <div className='about bg-neutral-100 grid grid-cols-1 md:grid-cols-3'>
                <img src={sun2} className="justify-center w-full rounded-3xl" alt="Dr. Sun"/>
                <div className='col-span-2'>
                {/* Title */}
                    <div className='text-5xl flex justify-center md:justify-start'>{summary? "Dr. Xiankui Sun":"About Dr. Xiankui Sun"}</div>
            
                    {/* Social Media Icons */}
                    {summary?null:<div className='flex flex-row pt-12 space-x-3 justify-center md:justify-start'>
                        <Gmail ratio={0.5}/>
                        <Instagram ratio={0.5}/>
                        <Facebook ratio={0.5}/>
                    </div>}
                    <div className="w-full flex items-center text-2xl">
                        {/* Bio Text */}
                        <div className="pt-12">
                            {summary ? <div className='flex flex-col gap-y-12'><div className='w-full'>{bioDescriptionSummary}</div>
                                            <div><button className='text-white rounded-2xl bg-slate-600 hover:bg-slate-400 pr-5 pl-5 pt-2 pb-2 border-0' onClick={() => window.location.href = './about'}>Learn More
                                            </button></div>
                                        </div>:<div className='pr-12 pl-12 md:pl-0'>             
                                                {bioDescription[1]}
                                                <br/><br/> 
                                                {bioDescription[2]}
                                                <br/><br/> 
                                                {bioDescription[3]}
                                            </div>
                            }
                            <div className='grid grid-cols-3 mb-16 text-center text-balance text-lg gap-x-4 pt-9 pr-4 pl-4'>
                                <div className='bg-slate-300 flex-col rounded-2xl mt-6 '>
                                    <div className='m-7'>
                                        <DoctorSVG/>
                                        <div>30+ years of Clinical Experience</div>
                                    </div>
                                </div>
                                <div className='bg-slate-300 flex-col rounded-2xl mt-6 '>
                                    <div className='m-7'>
                                        <Lamp/>
                                        <div>Highly Equipped Acupuncture Clinic</div>
                                    </div>
                                </div>
                                <div className='bg-slate-300 flex-col rounded-2xl mt-6 '>
                                    <div className='m-7'>
                                        <Brain/>
                                        <div>Specializes in Pain & Aging</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>
}




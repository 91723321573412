import {NavLink} from "react-router-dom";
import "./Navigation.css";

export const NavigationLinks = (props) => {
    return(
        <nav className="place-content-center lg:place-content-end md:place-content-center font-light">
            <ul className="block">
                <li>
                    <NavLink activeClassName="active" to="/" onClick={props.onMenuChange}>Home</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/about" onClick={props.onMenuChange}>About</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/services" onClick={props.onMenuChange}>Services</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/blogs" onClick={props.onMenuChange}>Blogs</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/qa" onClick={props.onMenuChange}>FAQs</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/insurance" onClick={props.onMenuChange}>Insurance</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to="/book" onClick={props.onMenuChange}>Book</NavLink>
                </li>
            </ul>
        </nav>)}
import { useState } from 'react';
import {blog_dict, blog_list} from './blog_list'

export const Blogs = () => {
    const [selectedBlog, setSelectedBlog] = useState(null);

    const handleBlogClick = (blogId) => {
        setSelectedBlog(blogId);
    };

    const num_blogs = 13
    const blog_posts = [...Array(num_blogs).keys()].reverse();

    return <div className='min-h-screen bg-blue-50 py-12'>
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
            {/* Title */}
            <h1 className="text-5xl font-bold text-center pt-4">Blog</h1>

            {/* Render grid of blog cards */}
            {/* className="grid lg:grid-cols-3 md:grid-cols-2 overflow-y-scroll content-normal px-20 pt-16 pb-50 gap-x-9 gap-y-14 flex-1 h-dvh bg-white"> */}
            <div className="flex flex-col w-full md:grid md:grid-cols-2 lg:grid-cols-3 gap-x-9 gap-y-14 py-12">
                
                {/* Blog preview cards */}
                {blog_list.map((blog_item) => {

                    // Extract a preview of the first few words (e.g., first 20 words)
                    const preview = blog_item.paragraph.split(" ").slice(0, 20).join(" ") + "...";

                    return <button className="flex flex-col gap-1 p-8 rounded-2xl border-none bg-transparent" key={blog_item.id}>
                        <div className='aspect-[421/275] mx-auto overflow-hidden'>
                            <a href={`/blogs/${blog_item.id}`}>
                                <img src={blog_item.image} 
                                    alt="thumbnail" 
                                    className="w-full h-full object-fill rounded-3xl"/>
                            </a>
                        </div>
                        
                        {/* bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden flex flex-col */}
                        <div className='flex flex-col gap-3 items-start w-full mt-4 mx-auto'>
                            <span className="font-semibold text-4xl">{blog_item.title}</span>
                            <span className="text-3xl text-start">{preview}</span>
                            <a href={`/blogs/${blog_item.id}`}>
                                <span className="text-2xl">Read More</span>
                            </a>
                        </div>
                        
                    </button>
                })}        
            </div>
        </div>
    </div>
}   

export default Blogs;
import Blog1 from "./blog/Blog1";
import Blog2 from "./blog/Blog2";
import Blog3 from "./blog/Blog3";
import Blog4 from "./blog/Blog4";
import Blog5 from "./blog/Blog5";
import Blog6 from "./blog/Blog6";
import Blog7 from "./blog/Blog7";
import Blog8 from "./blog/Blog8";
import Blog9 from "./blog/Blog9";
import Blog10 from "./blog/Blog10";
import Blog11 from "./blog/Blog11";
import Blog12 from "./blog/Blog12";
import Blog13 from "./blog/Blog13";
import img1 from './img/blog1.jpeg';
import img2 from './img/blog2.jpeg';
import img3 from './img/blog3.jpg';
import img4 from './img/blog4.jpeg';
import img5 from './img/blog5.jpeg';
import img6 from './img/blog6.jpeg';
import img7 from './img/blog7.jpg';
import img8 from './img/blog8.jpg';
import covid from './img/COVID19-acupuncture.jpg';
import points2 from './img/points2-xs.gif';
import img9 from './img/blog9.jpg';
import img10 from './img/blog10.jpg';
import img11 from './img/blog11.jpg';

const blog_dict = {
    1: [<Blog1/>, "What is Qi?", img1],
    2: [<Blog2/>, "Migraines", points2],
    3: [<Blog3/>, "COVID-19 and Acupuncture", covid],
    4: [<Blog4/>, "Psoriasis", img4],
    5: [<Blog5/>, "Morning Sickness", img5],
    6: [<Blog6/>, "Restless Leg Syndrome", img6],
    7: [<Blog7/>, "Bell's Palsy", img11],
    8: [<Blog8/>, "Anxiety Disorders", img2],
    9: [<Blog9/>, "Infertity", img9],
    10: [<Blog10/>, "Chemotherapy and Acupuncture", img3],
    11: [<Blog11/>, "Acupuncture for Back Pain", img10],
    12: [<Blog12/>, "Chinese Medicine Diagnosis Process", img8],
    13: [<Blog13/>, "IBS", img7],
}

const blog_list = [
    {
        id: 13,
        title: "IBS",
        image: img7,
        component: <Blog13/>,
        paragraph: "Irritable bowel syndrome (IBS) is a complex disorder that affects many individuals. It is characterized by recurrent abdominal pain and changes in bowel movements, including diarrhea, constipation, or both. While the exact cause of IBS is still unknown, several factors are thought to contribute to its development, such as intestinal muscle contractions, nervous system issues, severe infections, early-life stress, and changes in gut microbes."
    },
    {
        id: 12,
        title: "Chinese Medicine Diagnosis Process",
        image: img8,
        component: <Blog12/>, 
        paragraph: "As a licensed acupuncturist, I have a deep understanding of the Traditional Chinese Medicine (TCM) diagnosis process. TCM has been practiced for over 2,000 years, and at its core is the idea that the body, mind, and spirit are interconnected and interdependent. In order to diagnose and treat imbalances or blockages in the body's vital energy, or Qi, I use a variety of techniques known as the 'four main diagnostic methods'. These methods include inspection (observation), auscultation and olfaction (listening and smelling), interrogation (inquiry or questioning), and palpation (pulse examination)."
    },
    {
        id: 11,
        title: "Acupuncture for Back Pain",
        image: img10,
        component: <Blog11/>,
        paragraph: "Back pain is a common medical problem that affects millions of people in the United States. While there are various treatment options available, acupuncture is a safe and effective alternative that can help alleviate back pain. In this article, we will explore the benefits of acupuncture for back pain and how it works."
    },
    {
        id: 10,
        title: "Chemotherapy and Acupuncture",
        image: img3,
        component: <Blog10/>, 
        paragraph: "Chemotherapy is a type of cancer treatment that uses powerful chemicals to kill fast-growing cells in your body. This works because cancer cells tend to grow and divide rapidly and uncontrollably. However, the body also includes numerous healthy cells that naturally grow at a fast pace. Chemotherapy treatments cannot differentiate between cancer cells and other fast-growing cells, which is why chemotherapy also harms or kills healthy cells."
    },
    {
        id: 9,
        title: "Infertity",
        image: img9,
        component: <Blog9/>,
        paragraph: "If you and your partner are struggling to have a baby, you are not alone. In the United States, 10% to 15% of couples are infertile."
    },
    {
        id: 8,
        title: "Anxiety Disorders",
        image: img2,
        component: <Blog8/>,
        paragraph: "Occasional anxiety is an expected part of life. You might feel anxious when faced with a problem at work, taking an exam, or before making an important decision. But anxiety disorders involve more than temporary worry or fear. For a person with an anxiety disorder, the anxiety does not go away and can get worse over time. The symptoms that develop can interfere with daily activities such as job performance, school work, and relationships."
    },
    {
        id: 7,
        title: "Bell's Palsy",
        image: img11,
        component: <Blog7/>,
        paragraph: "Bell's Palsy, also known as idiopathic facial palsy, is a form of temporary facial paralysis or weakness on one side of the face. It results from the dysfunction - often swelling and inflammation - of cranial nerve VII (facial nerve) which directs the muscles on one side of the face. These muscles control closing your eyes, blinking, and facial expressions such as smiling. The facial nerve is also responsible for many tasks such as controlling the tear glands, saliva glands, and muscles of a small bone in the middle of the ear. It also transmits taste sensations from the tongue."
    },
    {
        id: 6,
        title: "Restless Leg Syndrome",
        image: img6,
        component: <Blog6/>,
        paragraph: "Restless legs syndrome (RLS), also called Willis-Ekbom Disease, causes unpleasant or uncomfortable sensations in the legs and an irresistible urge to move them. Symptoms commonly occur in the late afternoon or evening hours, and are often most severe        at night when a person is resting, such as sitting or lying in bed. This may also occur when someone is inactive and sitting for extended periods (for example, when taking a trip by plane or watching a movie). Since symptoms can increase in severity during the night, it could become difficult to fall asleep or return to sleep after waking up. Moving the legs or walking typically relieves the discomfort but the sensations often recur once the movement stops."
    },
    {
        id: 5,
        title: "Morning Sickness",
        image: img5,
        component: <Blog5/>,
        paragraph: "Morning sickness is nausea and vomiting that occurs during pregnancy, which is often triggered by certain odors, spicy foods, heat, excess salivation, or, oftentimes no triggers at all."
    },
    {
        id: 4,
        title: "Psoriasis",
        image: img4,
        component: <Blog4/>,
        paragraph: "Psoriasis is a chronic, immune-mediated, relapsing-remitting inflammatory dermatosis triggered by multiple environmental and endogenous factors in genetically susceptible individuals. Clinically, psoriasis usually appears as recurrent episodes of well-demarcated, scaly erythematous plaques and, in rare cases, it can manifest as generalized "
    },
    {
        id: 3,
        title: "COVID-19 and Acupuncture",
        image: img3,
        component: <Blog3/>,
        paragraph: "COVID-19 (Coronavirus) is the infectious disease that has been spreading all over the world, causing this pandemic. The virus’s full name, severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2), may sound threatening. However, it is not as dangerous as the name may indicate. Most people in"
    },
    {
        id: 2,
        title: "Migraines",
        image: img2,
        component: <Blog2/>,
        paragraph: "Migraine is such a familiar word, because you, your friends, your family members, or coworkers may suffer from it. There are more than thirty-nine thousands papers related to migraine in PubMed."
    },
    {
        id: 1,
        title: "What is Qi?",
        image: img1,
        component: <Blog1/>,
        paragraph: "Qi (chee) is a unique Chinese word, which is hard to find a perfect English translation. The concept of Qi originally comes from ancient Chinese philosophy. Qi is the primary element, which composes the whole of the universe. In Traditional Chinese Medicine (TCM), the most popular concept of Qi is that of our"
    },
]

export { blog_dict, blog_list };
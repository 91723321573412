export const Blog3 = () => {

    return (
        <div>
            COVID-19 (Coronavirus) is the infectious disease that has been spreading all over the world, causing this pandemic. The virus’s full name, severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2), may sound threatening. However, it is not as dangerous
            as the name may indicate. Most people in the United States who contract the Coronavirus will only have minor symptoms, such as a mild fever and cough, just like the common cold. Many—especially children and young adults—will actually be asymptomatic.
            There is a real risk for everyone, but COVID-19 disproportionately affects older folks, people with underlying health conditions, and health care workers who have repeated interactions with sick patients. Scientists believe that COVID-19 was
            originally transmitted to human from animals, and then from person to person by respiratory droplets or contaminated surfaces. Because there is no specific medicine to treat COVID-19, prevention is becoming extremely important. Other than
            staying at home and washing hands, increasing your own body’s antivirus abilities is the key to preventing COVID-19. This antivirus ability is the function of your own immune system, which needs to be strong enough to fight the virus. The
            following methods can help you increase the strength of your immune system.


            <br/><br/>
            The following methods can help you increase the strength of your immune system:

            <div>
                <br/>
                <ol className="list-disc">
                    <li>Retain inner peace. COVID-19 has its risks but is not as deadly as some media portrays it to be. Avoid lingering on the news and social media. This can help reduce stress and anxiety which will be beneficial for your immune system.
                    </li><br/>
                    <li>Eat a healthy and balanced diet and get a good night’s sleep. A healthy lifestyle can greatly boost your immune system.
                    </li><br/>
                    <li>Moxibustion method*. This technique helps to strengthen your immune system, which prevents diseases and maintains health. When applied to certain acupuncture points, moxibustion can help with fatigue, digestive issues, and much more,
                        ultimately preparing your body to fend off viruses. This method can be easily done at home: relaxing under the sunlight for 20-30 minutes each day serves as a natural moxibustion.
                    </li><br/>
                    <li>Acupuncture can also be used to prevent COVID-19. Through the stimulation of different acupuncture points, acupuncture strengthens your Qi (life force energy) and helps to move Qi more smoothly through the meridian channels of your
                        body. At the same time, it will balance your organs and immune system strengths, which will help your body combat the virus. **
                    </li>
                </ol>
            </div>

            <br/>
            *Due to the limitations of my clinic, I am unable to offer moxibustion services. If you are interested, I will be more than happy to teach you how you can conduct moxibustion on your own.

            <br/><br/>
            **To help Traditional Chinese Medicine practitioners battle the coronavirus outbreak, the China Association of Acupuncture and Moxibustion has issued a new booklet:
            <br/>
            <i>Acupuncture Treatment Guidelines for COVID-19 (Second ZWSPEdition). Chinese Acupuncture and Moxibustion:1-2[2020-03-21].</i>

        </div>
    );
};

export default Blog3

import cupping from './img/cuppings.gif';
import acupuncture from './img/acupuncture.gif';
import moxibustion from './img/moxibustion.jpg';
import React from "react";

const acuShort = "Acupuncture is a system of integrative medicine that involves pricking the skin or tissues with very thin needles. It is used to alleviate pain and treat various physical, mental, and emotional conditions.";
const acuComplete = "Acupuncture is a system of integrative medicine that involves pricking the skin or tissues with very thin needles. It is used to alleviate pain and to treat various physical, mental, and emotional conditions. Chinese medicine and acupuncture work to treat the whole body, mind, spirit, and emotions to address the problem at its roots. Originating in ancient China, acupuncture is now widely practiced in the West."

const cupShort = 'Cupping therapy is a technique in Traditional Chinese Medicine that requires placing vacuumed cups on the back, shoulders or other areas of your body. They dredge meridians to relieve pain, expel pathogenic factors, or clear blood stasis to increase blood circulation.';
const cupComplete = 'Cupping therapy is another common technique in Traditional Chinese Medicine. The practitioner will put vacuumed cups, which are made from different materials such as glass, plastic or bamboo, on your back, shoulders or other areas of your body; they can remain stationary or be moved around. Cupping therapy can dredge meridians to relieve pain, expel pathogenic factors, or clear blood stasis to increase blood circulation.';

const moxShort = 'Moxibustion involves burning herbs and applying the resulting heat to specific points on the body. Usually administered in conjunction with acupuncture, moxibustion is ideal for digestive issues, gynecological problems, and boosting immune functions after chemotherapy.';

const moxComplete = 'Moxibustion is an alternative therapy, commonly used in Traditional Chinese Medicine, that involves burning herbs and applying the resulting heat to specific points on the body. Typically administered in conjunction with acupuncture, moxibustion is good for digestive issues, gynecological problems, and boosting immune functions after chemotherapy.';

export const ServiceSummary = (props) => {
    const acuMsg = props.summary ? acuShort : acuComplete;
    const cupMsg = props.summary ? cupShort : cupComplete;
    const moxMsg = props.summary ? moxShort : moxComplete;
    
    return  <div className="text-left">
    <div className="grid grid-cols-1 space-y-5 md:grid-cols-3 lg:space-x-5 text-3xl pb-8">
        <div className="flex flex-col place-items-center bg-white rounded-3xl">
            <div className="space-y-5 p-8">
                <img src={acupuncture} className="w-full rounded-3xl" alt=''/>
                <div><a href="/qa">
                    Acupuncture
                </a></div>
                <div className='text-2xl'>{acuMsg}</div>
            </div>
        </div>
        <div className="flex flex-col place-items-center bg-white rounded-3xl">
            <div className="space-y-5 p-8">
                <img src={cupping} className="w-full rounded-3xl" alt=''/>
                <div><a href="/qa">
                    Cupping
                </a></div>
                <div className='text-2xl'>{cupMsg}</div>
            </div>
        </div>
        <div className="flex flex-col place-items-center bg-white rounded-3xl">
            <div className="space-y-5 p-8">
                <img src={moxibustion} className="w-full rounded-3xl" alt=''/>
                <div><a href="/qa">
                    Moxibustion
                </a></div>
                <div className='text-2xl'>{moxMsg}</div>
            </div>
        </div>
    </div>
    </div>
}
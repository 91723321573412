import {Gmail} from "../svg/Gmail";
import {Instagram} from "../svg/Instagram";
import {Facebook} from "../svg/Facebook";
import {NavigationLinks} from "../navigation/Navigation";

export const Footer = () => {
    return <footer className="bg-new-beige">
        <div className='flex flex-col p-16 divide-solid divide-x-0'>
            <div className='flex flex-row pb-4'>
                <div className='w-5/6'>
                    <div className='text-4xl'>Sun's Acupuncture</div>
                    <div className='text-2xl pb-16 pt-5'>Traditional Healing in a Modern Setting</div>
                    <div className='text-2xl'>Suite 11, 2002 Hogback Rd Ann Arbor, MI 48105</div>
                    <div className='flex flex-row w-full place-content-between'>
                        <div className='text-2xl'>Contact us at 734-277-2342</div>
                    </div>
                </div>
                <div className='w-1/6 flex flex-col text-2xl gap-y-4 place-items-end text-right'>
                    <NavigationLinks/>
                    <div className='space-x-3'>
                        <Gmail ratio={0.7}/>
                        <Instagram ratio={0.7}/>
                        <Facebook ratio={0.7}/>
                    </div>
                </div>
            </div>
            <div className="w-full md:flex md:items-center place-content-end border-b-0">
              <span className="text-sm p-4 text-gray-500 sm:text-center dark:text-gray-400">© 2021
                  <a href="www.regeneqi.com" className="hover:underline">RegeneQi</a>. All Rights Reserved.
            </span>
            </div>
        </div>
    </footer>

}
import React from "react";

export const Question = (props) => {
    return <div className='flex flex-col'>
        <div className='relative overflow-hidden rounded-2xl outline outline-slate-400'>
            <input type='checkbox'
                   className='peer absolute top-0 left-0 w-full h-32 opacity-0 z-10 cursor-pointer'/>
            <div className='bg-white flex flex-row gap-x-4 text-3xl pl-6 md:pl-20 place-items-center h-32'>
                {props.title}
            </div>
            <div className='absolute top-7 right-8 transition-transform duration-500                
                rotate-0 peer-checked:rotate-45 text-5xl'>
                +
            </div>
            <div className='bg-white text-2xl w-full overflow-hidden
             transition-all duration-500 ease-in-out
             max-h-0 peer-checked:max-h-full
             '>
                <div className='pl-6 pr-6 pt-6 pb-20 md:pl-20 md:pr-20'>
                    <div className="pb-20">
                    {props.content}
                    </div>
                    <a className='viewMore rounded-2xl bg-slate-600 pr-20 pl-20 pt-4 pb-4 text-white hover:text-white' href="./services">View Services</a>
                </div>
            </div>
        </div>
    </div>
}

export default Question;
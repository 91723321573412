import { BCBS } from "../svg/BCBS";
import { Medicare } from "../svg/Medicare";
import { Aetna } from "../svg/Aetna";
import { Humana } from "../svg/Humana";
import { PriorityHealth } from "../svg/PriorityHealth";
import { UnitedCare } from "../svg/UnitedCare";

export const insuranceList = [
    {
      name: 'Blue Cross Blue Shield',
      logo: <BCBS/>
    },
    {
      name: 'Aetna',
      logo: <Aetna/>
    },
    {
      name: 'Humana',
      logo: <Humana/>
    },
    {
      name: 'Priority Health',
      logo: <PriorityHealth/>
    },
    {
      name: 'United Healthcare',
      logo: <UnitedCare/>
    },
    {
        name: 'Medicare.gov',
        logo: <Medicare/>
    },
];

export const InsuranceProviders = ()=>{
    return <div className="flex justify-center md:justify-start">
    <div className="grid grid-cols-1 lg:grid-cols-2 justify-center gap-x-16 gap-y-8 w-full max-w-4xl">
        {insuranceList.map((provider) => (
            <button
                key={provider.name}
                className="h-36 lg:w-96 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow duration-200 flex items-center justify-center border border-blue-600">
                {provider.logo}
            </button>
        ))}
    </div>
</div>

}
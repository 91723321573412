export const Blog5 = () => {
    return (
        <div>
            Morning sickness is nausea and vomiting that occurs during pregnancy, which is often triggered by certain odors, spicy foods, heat, excess salivation, or, oftentimes no triggers at all.

            <br/><br/> Despite its name, morning sickness can strike at any time of the day or night. Morning sickness usually begins around the 6th week of pregnancy, peaks between weeks 8-11, and typically fades by the end of the first trimester. However,
            some women can experience it well into their second and even third trimester.

            <br/><br/> Morning sickness is not harmful to you or your baby, but rarely, if you experience excessive vomiting and cannot manage to keep your food down, you may have a condition called hyperemesis gravidarum. This is when someone with nausea
            and vomiting of pregnancy has severe symptoms that may cause severe dehydration or result in the loss of more than 5 percent of pre-pregnancy body weight. Hyperemesis gravidarum may require hospitalization and treatment with intravenous (IV)
            fluids, medications, and, on rare occasions, a feeding tube.

            <br/><br/> While the exact cause of morning sickness isn't well understood, many doctors believe it is hormonal in nature. The pregnancy hormone hCG reaches its highest level around the same time morning sickness occurs.

            <br/><br/> In the theory of Traditional Chinese Medicine (TCM), morning sickness is associated with the disharmony of the ‘Stomach/Spleen’ and ‘Liver’. Upon conception, there is increased Qi and ‘Blood’ supply to the womb for fetal development,
            strongly enhancing the Qi of the Chong Meridian. The Chong Meridian is known to govern the Qi and ‘Blood’ circulation in all main Meridians, and its function is closely linked to the reproductive system. The boost in Qi may affect the digestive
            system, leading to an abnormal upsurge of Stomach Qi, causing nausea and vomiting. This is especially evident in women with weaker digestion (weaker ‘Spleen’ function).

            <br/><br/> In TCM, the ‘Liver’ is known to store and regulate the circulation of ‘Blood’. More ‘Blood’ is channeled to the womb during pregnancy, causing the relative decrease of ‘Blood’ (Yin) in the ‘Liver’. The deficiency in the Yin levels
            results in its inability to curb the ‘Liver Fire’ (Yang). The excess in ‘Liver Fire’ can upset the Stomach Qi, causing nausea and vomiting too.

            <br/><br/> Acupuncture can subdue the effects of the ‘Liver Fire’ on the stomach, thus stopping the symptoms of morning sickness. This is done by inserting very thin needs in a few acupoints, such as PC6, ST36, and SP4, which balances Qi and ‘Blood’ in the Chong meridian. Acupuncture is widely and effectively used in clinics to
            treat morning sickness.

        </div>
    );
}
export default Blog5